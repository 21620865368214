import { Alert, Card, Divider, Drawer, message, Modal, Table, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TableLZM from '../../../components/design/table/TableLZM';
import { AddressGroup } from '../../../components/model/business/FieldsGroup';
import Post from '../../../components/model/posts/Post';
import { authRequest } from '../../../../common/request/authRequest';
import { ButtonLZ } from '../../../components/design/Buttons/ButtonLZ';
import { authFetch } from '../../../../common/request/authFetch';
import { getProjectStorage } from '../../../../store/storageContext';
import { useAuth } from '../../../../store/AuthContext/AuthContext';
import { useIsAuthorized } from '../../common/roles/Roles';
import moment from 'moment';
import { POSTTYPES } from '../../../../common/constants/postTypes';
import { PostScheduled } from '../../../components/model/posts/PostScheduled';
import { CalendarOutlined, CalendarTwoTone, DeleteOutlined } from '@ant-design/icons';
import { useFetch } from '../../../../hooks/useFetch/useFetch';

export const PostSheduledDrawer = (props: { postScheduled: any, onFinish: any, onChange: any }) => {

  const [postLocationsData, setPostLocationsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const isAuthorized = useIsAuthorized();
  const { groupAccount } = useAuth();

  useFetch(`${process.env.REACT_APP_API}/projects/` + getProjectStorage() + '/' + groupAccount + `/postsScheduled/${props.postScheduled.id}`, {}, [],
		(data) => {		    
      setPostLocationsData(data);
	});

  const deleteScheduledPost = async() => {
    await authFetch(`${process.env.REACT_APP_API}/projects/${getProjectStorage()}/${groupAccount}/postsScheduled/${props.postScheduled.id}` , { method: 'DELETE'})
    .then((res) => {
      if (res) {
          message.success('Programación eliminada correctamente', 3);
          props.onChange();
      } else {
          message.error('Error inesperado al borrar la programación', 3);
      }
    })
  }
  
  const columns = [
    {
      title: 'Title',
      key: 'title',      
      render: (record: any) => (
			  <Tag style={{borderRadius:'5px'}}>{record.title}</Tag>
			  )
    },
    {
    title: 'Ubicación',
    key: 'addressLine',
    render: (text: string, record: any) => (<AddressGroup record={record} />),
    dataIndex: 'addressLine'
    }
  ]

  return (
    <Drawer
      bodyStyle={{ background: '#F4F7FA' }}
      open={true}
      title={
        <div style={{ display: 'flex', justifyContent:'space-between'}}>
          <div>
            <h2> <strong>{moment(props.postScheduled.post.scheduleDate).format('ll')}</strong></h2>                    
            <Tag style={{ borderRadius: '20px'}}><CalendarOutlined/> Programado</Tag>            
          </div>
          <Alert message={<>{props.postScheduled.post.locationsNames.length} locales</>} type="info" />
        </div>
      }
      placement="right"
      width={'45%'}
      onClose={() => props.onFinish()}>
            
      <h2 style={{color:'LightSlateGray'}}>Programación:</h2>
      <div style={{ width: '80%', margin: '0px 10% 20px 10%' }}>    
        <PostScheduled postScheduled={props.postScheduled} />                
      </div>
        
      <h2 style={{color:'LightSlateGray'}}>Lista de locales:</h2>
      <TableLZM rowKey={'name'} columns={columns} dataSource={postLocationsData} loading={isLoading} showHeader={false}/>
      <div style={{ display: 'flex', justifyContent:'flex-end', marginBottom:'5px'}}>          
          <ButtonLZ
              disabled={!isAuthorized(['ROLE_ADMIN', 'ROLE_AGENT', 'ROLE_CLIENT', 'ROLE_GUEST_CONTENT_EDIT'])}
              onClick={
                () => {
                  Modal.confirm({
                    title: '¿Confirma el borrado de esta programación?',
                    okText: 'Confirmar',
                    cancelText: 'Cancelar',
                    onOk() {
                      deleteScheduledPost()
                    }
                  });
                }
              }>
                <DeleteOutlined/> Eliminar
            </ButtonLZ>
          </div>
    </Drawer>
  )
}