import { Card, Col, Divider, Rate, Row, Tag } from "antd";
import { RingProgress } from "@ant-design/charts";
import { ShopOutlined, StarFilled, StarOutlined } from "@ant-design/icons";
import MathUtils from "../../../../common/utils/MathUtils";
import { useTranslation } from "react-i18next";
import reviewsIcon from '../../../../assets/icons/REVIEWS-ICON-light.png';
import { Increment } from "../../../../common/utils/Increment";
import { ColorStar } from "../../../../common/utils/ColorStar";

export const BlockReviewsKpis = (props: {reviewsKPIs: any}) => {

    const { t } = useTranslation();

    return (
        <>
          <Row gutter={36} style={{ marginBottom: '20px' }}>
                <Col span={9}>
                    <Card bodyStyle={{ backgroundColor: 'white', borderRadius: '25px', height: '180px' }}>
                        <div className="card-kpis">
                            <h1>{t('reviews.page.global.cards.averageRating')}</h1>
                        </div>
                        <div style={{display:'flex', alignItems:'center', justifyContent:'space-around'}}>
                            {props.reviewsKPIs.rateMin && 
                                <div style={{marginTop:'6px'}}>                            
                                    Mínimo
                                    <ColorStar style={{ fontSize: '18px' }} value={props.reviewsKPIs.rateMin}/>								
                                </div>
                            }
                            <div className="rating-container">
                                <img src={reviewsIcon} style={{height:'60px', borderRadius: '17px'}}/>                                    
                                <span >
                                    {props.reviewsKPIs?.rateAvg === null ? '-' : MathUtils.round(props.reviewsKPIs.rateAvg, 1)}
                                </span>                                
                            </div>
                            {props.reviewsKPIs.rateMax && 
                                <div style={{marginTop:'6px'}}>                            
                                    Máximo
                                    <ColorStar style={{ fontSize: '18px' }} value={props.reviewsKPIs.rateMax}/>								
                                </div>
                            }
                        </div>
                        <div style={{display:'flex', justifyContent:'space-around', color:'steelblue'}}>
                                <div>
                                    {props.reviewsKPIs.numberOfRecentReviews > 0 && <span><StarOutlined/>{MathUtils.round(props.reviewsKPIs.recentRateAvg, 1)} últimos 30d </span>}
                                    {/*<Increment numberOfDecimals={2} value={props.reviewsKPIs.rateAvgIncrement}/>*/}
                                </div>                            				
                        </div>                        
                    </Card>
                </Col>
                <Col span={6}>
                    <Card bodyStyle={{ backgroundColor: 'white', borderRadius: '25px', height: '180px' }}>
                        <div className="card-kpis">
                            <h1 >{t('reviews.page.global.cards.totalReviews')}</h1>
                        </div>
                        <div className="values-kpis">
                            <span><b>{props.reviewsKPIs.numberOfReviews}</b></span>
                        </div>
                        <div style={{display:'flex', justifyContent:'space-around', color:'steelblue'}}>
                            <span><b>{props.reviewsKPIs?.numberOfRecentReviews}</b> últimos 30d</span>  
                        </div>
                    </Card>
                </Col>
                <Col span={9}>
                    <Card bodyStyle={{ backgroundColor: 'white', color:'steelblue', borderRadius: '25px', height: '180px' }}>
                        <div className="card-kpis">
                            <h1>Por local</h1>
                            <h1 style={{color:'steelblue'}}>últimos 30d</h1>
                        </div>
                        <div className="values-kpis" style={{justifyContent:'space-around'}}>
                            <span>
                                {props.reviewsKPIs.numberOfRecentReviews > 0 ?
                                    props.reviewsKPIs.reviewsPerMonth?.toFixed(1)
                                    :
                                    <>-</>}
                            </span>  
                            <span>
                                {props.reviewsKPIs.numberOfRecentReviews > 0 ?
                                <span>{MathUtils.round(100 * props.reviewsKPIs.recentRepliedRatio, 1)} %</span>
                                :
                                <>-</>}
                            </span>                            
                        </div>
                        <div style={{display:'flex', justifyContent:'space-around'}}>
                            <span style={{color:'steelblue'}}>reseñas/mes</span>  
                            <span style={{color:'steelblue'}}>ratio de respuesta</span>  
                        </div>
                    </Card>
                </Col>                    
            </Row>                   
        </>
    )
}