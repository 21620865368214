import { PlusOutlined } from '@ant-design/icons';
import { Form, Upload, message } from 'antd';
import React, { useState } from 'react';
import { useAuth } from '../../../../../store/AuthContext/AuthContext';
import { useTranslation } from 'react-i18next';

const FormItemUploadPhoto = (props: {maxPhotos: number, label? : string}) => {		

	const { token, project } = useAuth();
	const [uploadFileList, setUploadFileList] = useState<any[]>([]);
	const { t } = useTranslation();	

	const onUploadChange = (info : any) => {

		if (info?.fileList.length > 0) {
			let isImage = info.fileList[0].name.includes('png') || info.fileList[0].name.includes('jpg') || info.fileList[0].name.includes('jpeg');

			if (isImage) {
				if (info?.fileList[0]?.size! >= 5000000 || info?.fileList[0]?.size! <= 10000) {
					message.error(t('view.forms.fields.photo.errors.size'), 3)
					return
				}			
			} else {
				if (info?.fileList[0]?.size! >= 75000000) {
					message.error(t('view.forms.fields.photo.errors.size'), 3)
					return
				}
			}
		  }

		let fileList = [...info.fileList];		
		fileList = fileList.slice(-1 * props.maxPhotos);			
		fileList = fileList.map(file => {
		
			if (file.response) {
				file.url = file.response.url;
			}
			return file;
		});
		setUploadFileList(fileList);		
	}

	const uploadProps = {
		action: `${process.env.REACT_APP_API}/projects/${project}/media`,
		accept: 'image/jpeg,image/png,image/jpg,video/mp4,video/quicktime,video/x-msvideo,video/x-ms-wmv,video/x-flv,video/x-matroska,video/webm',
		headers: {
			Authorization: 'Bearer ' + token
		},		  
		onChange: onUploadChange,
		multiple: true,		 
		fileList: uploadFileList
	};

	const uploadButton = (
		<div>
		  <PlusOutlined />
		  <div style={{ marginTop: 8 }}>
			{t('view.forms.fields.photo.label')}
		  </div>
		</div>
	  );

  return (		
	<Form.Item label={props.label} name='photo'>							
		<Upload {...uploadProps} listType='picture-card'>
			{uploadFileList.length >= props.maxPhotos ? null : uploadButton}						
		</Upload>
	</Form.Item>						
	)
}

export default FormItemUploadPhoto;