import { BankOutlined, CalendarOutlined, CameraOutlined, CaretRightOutlined, ClockCircleFilled, ClockCircleOutlined, DoubleRightOutlined, EditOutlined, ForwardOutlined, LoadingOutlined, PercentageOutlined, SoundFilled, SoundOutlined, SyncOutlined } from '@ant-design/icons';
import { Drawer, Spin, Tag, Timeline, Tooltip } from 'antd';
import { useState,  useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetch } from '../../../../../hooks/useFetch/useFetch';
import { getProjectStorage } from '../../../../../store/storageContext';
import Operations from './Operations';
import { DrawerContext } from '../../../../../store/DrawerContext/DrawerContext';
import moment from 'moment';

const iconMap: any = {
  'PHOTO_LOGO': <CameraOutlined  style={{fontSize:'17px'}} />,
  'PHOTO_COVER': <CameraOutlined  style={{fontSize:'17px'}} />,
  'STANDARD': <SoundOutlined style={{fontSize:'17px'}} />,
  'OFFER': <SoundFilled  style={{fontSize:'17px'}} />,
  'EVENT': <SoundFilled  style={{fontSize:'17px'}}/>,
  'EDIT_LOGO': <EditOutlined   style={{fontSize:'17px'}}/>,
  'EDIT_COVER': <EditOutlined   style={{fontSize:'17px'}}/>,
  'EDIT_WEB':<EditOutlined   style={{fontSize:'17px'}}/>,
  'EDIT_REGULAR_HOURS':<EditOutlined  style={{fontSize:'17px'}} />,
  'EDIT_CATEGORIES':<EditOutlined   style={{fontSize:'17px'}}/>,
  'EDIT_PHONES':<EditOutlined   style={{fontSize:'17px'}}/>  
};

const statusMap: any = {
  'NOT_FINISHED': {name: 'Pregunta', color:'black'},  
  'RUNNING': {name: 'Empezado', color:'black'}, 
  'Running': {name: 'Empezado', color:'black'},  
  'FINISHED_KO': {name: 'Error', color:'red'},
  'FINISHED_OK': {name: 'Finalizado', color:'green'}
};

export const LogsDrawer = () => {

  const { t } = useTranslation();
  const [updateData, setUpdateData] = useState(false);
  const [operationsVisible, setOperationsVisible] = useState(false);
  const [job, setJob] = useState({});
  const [jobsData, setJobsData] = useState([]);

  const { logsDrawer, setLogsDrawer } = useContext(DrawerContext);

  const {isLoading } = useFetch(`${process.env.REACT_APP_API}/projects/` + getProjectStorage() + '/jobs', {}, [updateData], (data) => {
    setJobsData(data.jobs);    
    // setTimeout(() => {
    //   setUpdateData(!updateData);
    // }, 5000);		
  });    

  return (
    <Drawer title={<h2>{t('dictionary.bulkLogs')}<br/><small style={{color:'grey'}}>{getProjectStorage()}</small></h2>}  placement="right" width={'650px'} onClose={()=>setLogsDrawer(false)} visible={logsDrawer}>
     { 
      isLoading ? <div style={{display:'flex', justifyContent:'center', alignItems:'center', height: '100%'}}><Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} /></div> : 
      <div style={{padding:'20px'}}>
      <Timeline mode={'left'}>
        {
          jobsData.map((row : any) => {
            const icon = row.status === 'NOT_FINISHED' ? <SyncOutlined spin /> : iconMap[row.statusAction];
            return (
              <Timeline.Item 
                color={statusMap[row.status].color} 
                dot={icon}>
                  <div style={{display:'flex', justifyContent:'space-between'}}>
                    <b>{row.action}</b> 
                    <b><a style={{fontSize:'12px'}} onClick={() => {setOperationsVisible(true); setJob(row)}}>En {row.operations} {t('dictionary.shops').toLowerCase()}{' >>'}</a></b>
                  </div>                
                  <i style={{color:'grey', margin:0}}>{moment(row.created).format('ll')} por <Tooltip title={row.operator}>{row.operatorName}</Tooltip></i>
              </Timeline.Item>
              )
            })
          }                    
    </Timeline>
    </div>
    }
    
     {
      operationsVisible && (
        <Operations job={job} setVisible={setOperationsVisible}/>
      )}
  </Drawer>
	)
}