import { Alert, Badge, Card, Divider, Drawer, message, Modal, Table, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TableLZM from '../../../components/design/table/TableLZM';
import { AddressGroup } from '../../../components/model/business/FieldsGroup';
import Post from '../../../components/model/posts/Post';
import { authRequest } from '../../../../common/request/authRequest';
import { ButtonLZ } from '../../../components/design/Buttons/ButtonLZ';
import { authFetch } from '../../../../common/request/authFetch';
import { getProjectStorage } from '../../../../store/storageContext';
import { useAuth } from '../../../../store/AuthContext/AuthContext';
import { useIsAuthorized } from '../../common/roles/Roles';
import moment from 'moment';
import { POSTTYPES } from '../../../../common/constants/postTypes';
import { BarsOutlined, CheckCircleFilled, CheckCircleOutlined, CheckCircleTwoTone, ClockCircleOutlined, SoundOutlined } from '@ant-design/icons';

export const PostsHistoricalDrawer = (props: { setRefreshPosts:any, refreshPosts:boolean, postLocationsVisible: boolean, setPostLocationsVisible: any, postGroup: any }) => {

  const [postLocationsData, setPostLocationsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const isAuthorized = useIsAuthorized();
  const { groupAccount } = useAuth();


  useEffect(() => {
    setIsLoading(true);
    const data = {
      ids: props.postGroup.ids,
      status: 'CREATED'
    };

    authRequest(`${process.env.REACT_APP_API}/projects/${props.postGroup.projectId}/accounts/${props.postGroup.accountId}/postGroup`, { method: 'POST', data: data })
      .then((data) => {
        setPostLocationsData(data);
        setIsLoading(false);
      })
      .catch(() => {
        message.error(t('view.errors.loadStores'))
        setIsLoading(false);
      });
  }, []);



  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const deletePost = async() => {
    await authFetch(`${process.env.REACT_APP_API}/projects/${getProjectStorage()}/${groupAccount}/locations?locationsIds=${selectedRowKeys}` , { method: 'DELETE'}).then(()=>{
      props.setRefreshPosts(!props.refreshPosts)
      props.setPostLocationsVisible(false);

    })
  }

  const columns = [
    {
      title: '',
      key: 'title',      
      render: (record: any) => (
			  <>{record.title && <Tag style={{borderRadius:'5px'}}>{record.title}</Tag>}</>
			  )
    },
    {
    title: 'Ubicación',
    key: 'addressLine',
    render: (text: string, record: any) => (<AddressGroup record={record} />),
    dataIndex: 'addressLine'
  },
  {
    title: '',
    key: 'local',
    render: (text: string, record: any) => (<div style={{ fontWeight: 'bold' }}><a target={'_blank'} href={record.searchUrl}>{t('dictionary.viewOnGoogle')} {'>>>'}</a></div>),
    dataIndex: 'searchUrl'
  }]


  return (
    <Drawer
      bodyStyle={{ background: '#F4F7FA' }}
      open={props.postLocationsVisible}
      title={
        <div style={{ display: 'flex', justifyContent:'space-between'}}>
          <div>
            <h2 style={{color:'black'}}> <strong>{moment(props.postGroup.createdDate).format('ll')}</strong></h2>                    
            <Tag style={{ borderRadius: '20px'}}>{props.postGroup.postType}</Tag>            
          </div>
          <Alert message={<>{postLocationsData.length} locales</>} type="info" />
        </div>
      }
      placement="right" 
      width={'45%'}
      onClose={() => props.setPostLocationsVisible(false)}>

      <h2 style={{color:'LightSlateGray'}}>Publicación:</h2>
      <div style={{ width: '80%', margin: '0px 10% 20px 10%' }}>
        <Post postGroup={props.postGroup} />
      </div>
      
      <div style={{ display: 'flex', justifyContent:'space-between'}}>
        <h2 style={{color:'LightSlateGray'}}>Lista de Locales:</h2>
       {
          <ButtonLZ
            disabled={!(selectedRowKeys.length > 0) || !isAuthorized(['ROLE_ADMIN', 'ROLE_AGENT', 'ROLE_CLIENT', 'ROLE_GUEST_CONTENT_EDIT'])}
            onClick={
              () => {
                Modal.confirm({
                  title: '¿Confirma el borrado de estas publicaciones?',
                  okText: 'Confirmar',
                  cancelText: 'Cancelar',
                  onOk() {
                    deletePost()
                  }
                });
              }
            }>
              Borrar
          </ButtonLZ>
        }
      </div>
      <TableLZM rowSelection={rowSelection} rowKey={'name'} columns={columns} dataSource={postLocationsData} loading={isLoading} />
    </Drawer>
  )
}
