import { EditOutlined } from '@ant-design/icons'
import { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { IADefaultLanguageView } from './IADefaultLanguageView';
import { IADefaultLanguageEdit } from './IADefaultLanguageEdit';
import { useIsAuthorized } from '../../../../pages/common/roles/Roles';

export const IADefaultLanguage = (props: { language: string, groupAccount: string}) => {

    const [edit, setEdit] = useState(false);
    const [language, setLanguage] = useState(props.language);
    const [editVisible, setEditVisible] = useState(false);
    const [currentStoreCode, setCurrentStoreCode] = useState(props.language);
    const { t } = useTranslation();
    const isAuthorized = useIsAuthorized();
    
    //let locationId = props.locationsIds[0];

    const handleMouseEnter = () => {
        setEditVisible(true);
    };

    const handleMouseLeave = () => {
        setEditVisible(false);
    };
      
    return (
        <div  
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{backgroundColor:editVisible ? 'var(--light-grey)' : ''}}>        
            <span style={{ fontSize: "16px", cursor:'pointer', color:'LightSlateGray' }} onClick={() => setEdit(!edit)}>
                <span>Idioma por defecto </span>
                {editVisible && isAuthorized(['ROLE_ADMIN', 'ROLE_AGENT', 'ROLE_CLIENT']) && <EditOutlined />}                
            </span>
            {
                !edit
                ?
                <IADefaultLanguageView defaultLanguage={language!} />
                : 
                <IADefaultLanguageEdit                     
                    defaultLanguage={currentStoreCode}     
                    onEdit={(language: any) => {setLanguage(language); setEdit(false)}}
                    onCancel={() => {setEdit(false)}}                                                                        
                />
            }            
        </div>
    )
}
