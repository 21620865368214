import { useContext, useState } from 'react';
import { Card, Radio, Timeline, Tag, Row, Col, Pagination, Image, Badge, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useFetch } from '../../../../hooks/useFetch/useFetch';
import { useAuth } from '../../../../store/AuthContext/AuthContext';
import { CalendarFilled, CalendarOutlined} from '@ant-design/icons';
import { getProjectStorage } from '../../../../store/storageContext';
import Post from '../../../components/model/posts/Post';
import '../../pages.scss';
import { Synchronized } from '../../../components/design/Synchronized/Synchronized';
import { SCHEDULEDJOB } from '../../../../common/constants/services';
import TableLZM from '../../../components/design/table/TableLZM';
import moment from 'moment';
import { truncateText } from '../../../../common/utils/TextUtils';
import { ExpandableText } from '../../../../common/utils/ExpandableText';
import { POSTTYPES } from '../../../../common/constants/postTypes';
import { PostsHistoricalDrawer } from './PostsHistoricalDrawer';
import { PostSheduledDrawer } from './PostSheduledDrawer';
import { DrawerContext } from '../../../../store/DrawerContext/DrawerContext';

export const GooglePostsScheduled = () => {

	const [refreshPosts, setRefreshPosts] = useState(false);
	const { groupAccount } = useAuth();
	const [scheduled, setScheduled] = useState([]);
	const [postScheduledSelected, setPostScheduledSelected] = useState(null);
	const { setLogsDrawer } = useContext(DrawerContext)

	const { t } = useTranslation();

	useFetch(`${process.env.REACT_APP_API}/projects/` + getProjectStorage() + '/' + groupAccount + '/postsScheduled', {}, [refreshPosts],
		(data) => {
			setScheduled(data);			
	});

	const columns = [	  
	  {
		title: 'Foto',
		key: 'summary',
		align:'center',
		width: '200px',		
		render: (record: any) => (
			<>
				{record.post.photosURL.length > 0 ? 
					<Image
						width={200}
						style={{borderRadius:'20px'}}						
						src={record.post.photosURL[0]}
					/>
					:
					<h3 style={{color:'lightgrey'}}><b>Sin foto</b></h3>
				}
			</>
		)
	  },
	  {
		  title: 'Detalle',
		  key: 'summary',		
		  render: (record: any) => (
			  <div><ExpandableText text={record.post.summary}></ExpandableText></div>
			  )
		},		
		{
			title: '',
			width: '400px',
			key: 'local',
			render: (record: any) => (
				<Card bodyStyle={{ background: 'white', borderRadius: '20px' }}>
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>						
						<b><CalendarOutlined/> {moment(record.scheduledDate).format('ll')}</b>																						
						<Tag style={{ borderRadius: '20px' }}>{POSTTYPES[record.post.type]}</Tag>
					</div>
					<div>
						{
							record.scope === 'LOCATION' ? 
								<h3 style={{color:'LightSlateGray'}}>{record.locationTitle}</h3>
								:
								<h3 style={{color:'LightSlateGray'}}>En {record.locationIds.length} locales</h3>
						}
					</div>
										
					<div style={{ display: 'flex', justifyContent: 'flex-end', marginTop:'20px' }}>					
						<div style={{ fontWeight: 'bold' }}>						
							<a onClick={() => { setPostScheduledSelected(record) }}>VER DETALLE {'>>>'}</a>
						</div>
					</div>
				</Card>
			)
		}
	]

	return (
		<Card className='main-card-content'>
			
			<div style= {{width:'100%', display:'flex', justifyContent:'space-between'}}>
				<h1 className='title-card'>Calendario de Programación</h1>
				<Button type='link' onClick={() => setLogsDrawer(true)}>
					Ver Registro
				</Button>		
			</div>

			<Synchronized service={[SCHEDULEDJOB.GBP_POSTS]}/>	
			<div style={{ margin: '20px' }}>
				<TableLZM rowKey={'name'} columns={columns} dataSource={scheduled} loading={false} showHeader={false}/>				
			</div>			
			
			{postScheduledSelected && 
				<PostSheduledDrawer 
					postScheduled={postScheduledSelected} 
					onFinish={() => {setPostScheduledSelected(null)}}
					onChange={() => {setPostScheduledSelected(null); setRefreshPosts(!refreshPosts)}} />
			}			
		</Card>
	)
}