import React, { useState } from 'react';
import { truncateText } from './TextUtils';

export const ExpandableText = (props : { text: string}) => {
  
  const hasTruncatedText = props.text.length > 250;
  const [allText, setAllText] = useState(false);

  const truncatedText = hasTruncatedText ? truncateText(props.text, 250) : props.text;

  return (
    <p style={{ fontSize: '14px', fontWeight: 400 }}>
      {hasTruncatedText ? (
        allText ? (
          <span>
            {props.text} <a onClick={() => setAllText(false)}>Menos</a>
          </span>
        ) : (
          <span>
            {truncatedText} <a onClick={() => setAllText(true)}>Más</a>
          </span>
        )
      ) : (
        <span>{props.text}</span>
      )}
    </p>
  );
};