import { DatePicker, Form } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const FormItemSchedule = () => {		

  const { t } = useTranslation();

  return (		
		<Form.Item name='scheduleDate' style={{float:'right'}}>
			<DatePicker format='DD-MM-YYYY'	/>				
		</Form.Item>								
	)
}