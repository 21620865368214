import { Calendar, Image, Tag } from 'antd';
import { POSTTYPES } from '../../../../common/constants/postTypes';
import moment from 'moment';
import { CalendarOutlined } from '@ant-design/icons';

export const PostScheduled = (props: {postScheduled: any}) => {

  	return (	
		<>
			<div style={{fontSize:'16px', display:'block'}}>
				{
					!!props.postScheduled.post && !!props.postScheduled.post.photosURL && props.postScheduled.post.photosURL.map((googleUrl : string) => {
						return (<Image width={'100%'} src={googleUrl} preview={false} style={{borderRadius:'20px 20px 0px 0px', display:'inline'}}/>)
					})
				}    
			</div>			
			<div style={{padding:'15px', textAlign:'justify', background:'white', color:'rgba(0, 0, 0, 0.5)', border:'1px solid rgba(0, 0, 0, 0.1)'}}>
				<div style={{ display: 'flex', justifyContent:'space-between'}}>
					<div><Tag style={{ borderRadius: '20px' }}color={'LightSlateGray'}><CalendarOutlined/> {moment(props.postScheduled.scheduledDate).format('ll')}</Tag></div>
					<div><Tag style={{ borderRadius: '20px' }}>{POSTTYPES[props.postScheduled.post.type]}</Tag></div>
				</div>
				<div style={{ margin:'10px'}}>{props.postScheduled.post.summary}</div>
			</div>
		</>	  			          				
	)
}