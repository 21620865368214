import { Form, Input, message } from 'antd'
import  { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../../../store/AuthContext/AuthContext';
import { GlobalVariableContext } from '../../../../../store/GlobalVariableContext/GlobalVariableContext';
import { authFetch } from '../../../../../common/request/authFetch';
import { getProjectStorage } from '../../../../../store/storageContext';
import EditableInputItem from '../../business/forms/EditableInputItem';
import { ButtonLZ } from '../../../design/Buttons/ButtonLZ';

export const IADefaultLanguageEdit = (props: {defaultLanguage ?: string, onEdit : any, onCancel : any}) => {

    const { groupAccount } = useAuth();
    const [languageForm] = Form.useForm();
    const { t } = useTranslation();
    
    const editDefaultLanguage = async(values:any) => {

        const data = {
          defaultLanguage: values.language
        };

       await authFetch(`${process.env.REACT_APP_API}/projects/${getProjectStorage()}/${groupAccount}/ia/language`, { method: 'PATCH', data: data })
        .then((res) => {
          
          if (res) {
            message.success('Cambio de idioma realizado con éxito', 3);
            props.onEdit(values.language);            
          } else {
            message.error(t('Error imprevisto al cambiar el idioma'), 3)
          }
    }); 
    
    }
    return (
        <div>
          <Form layout='vertical' form={languageForm} onFinish={(values) => editDefaultLanguage(values)} initialValues={{language:props.defaultLanguage}}>
            <Form.Item rules={[{ required: true }]} name="language" style={{ width: '220px' }}>
              <Input />
            </Form.Item>            
            <div style={{ display: 'flex', justifyContent: 'flex-start', gap:'1em' }}>
              <ButtonLZ
                    htmlType="submit"
                    color='dark-white'                  
                    size='small'
                    shape='round'
                    width='35%'>
                    Aceptar
                </ButtonLZ>
                <ButtonLZ
                    onClick={props.onCancel}
                    color='dark-white'                  
                    size='small'
                    shape='round'
                    width='35%'>
                    Cancelar
                </ButtonLZ>
              </div>
          </Form>
        </div>
    )
}







