import { Button, Drawer, Radio, Switch, Tag } from 'antd';
import { useFetch } from '../../../../hooks/useFetch/useFetch';
import moment from 'moment';
import PDFGenerator from '../../../components/model/pdf/GeneratePDF';
import { BlockInfoKpis } from '../blocks/BlockInfoKpis';
import { BlockConversion } from '../blocks/BlockConversion';
import { BlockReviewsKpis } from '../blocks/BlockReviewsKpis';
import { BlockReviewsGlobal } from '../blocks/BlockReviewsGlobal';
import { BlockContentGlobal } from '../blocks/BlockContentGlobal';
import { BlockContentKpis } from '../blocks/BlockContentKpis';
import { useRef, useState } from 'react';
import { CommentOutlined, EditOutlined, ShareAltOutlined } from '@ant-design/icons';
import { CustomField, EditableBlock } from './form/EditableBlock';
import { PublicReport } from './PublicReport';
import Paragraph from 'antd/lib/typography/Paragraph';


export const ReportPreviewDrawer = (props : {reportId: string, onFinish: any}) => {

	const [isEdit, setIsEdit] = useState(false);	
	const [updateData, setUpdateData] = useState(false);	
	const pdfElement = useRef<HTMLDivElement>(null);

	//const { data: reportData, isLoading }: any = useFetch(`${process.env.REACT_APP_API}/reports/${props.reportId}`, {}, [updateData]);
	
  	return (		
		  <Drawer
			placement="bottom"
			height={'92%'}
			open={true}
			onClose={() => props.onFinish()}
			bodyStyle={{ padding:'0px', margin:'0px' }}
			title={
				<div style={{display:'flex', justifyContent:'space-between', padding:'10px'}}>
					<Radio.Group  value={isEdit} onChange={(obj: any) => setIsEdit(!isEdit)} optionType="button" buttonStyle="solid">
						<Radio.Button value={false}>Previsualización</Radio.Button>
						<Radio.Button value={true}>Edición</Radio.Button>
					</Radio.Group>
					<div style={{display:'flex', alignContent:'space-between', flexDirection:'column'}}>
						{!isEdit && <PDFGenerator element={pdfElement}/>}
						<Paragraph style={{marginTop:'10px'}} copyable={{ text: `${process.env.REACT_APP_MULTI}/public/reports/${props.reportId}`}}>Copiar Enlace</Paragraph>
					</div>
				</div>
			}
		>
			
		<PublicReport reportId={props.reportId} onFinish={props.reportId} isEdit={isEdit} pdfElement={pdfElement}/>
		
		</Drawer>
	)
}
