import { BarChartOutlined, BarsOutlined, CommentOutlined, QuestionCircleOutlined, DoubleRightOutlined, QrcodeOutlined, RollbackOutlined, StarOutlined, StarTwoTone, NotificationOutlined, ShopOutlined, AppstoreOutlined, SettingOutlined, LineChartOutlined, SnippetsOutlined, CloudUploadOutlined, CalendarOutlined, ShareAltOutlined } from '@ant-design/icons';
import { Layout as AntdLayout, Calendar, Menu } from 'antd';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../store/AuthContext/AuthContext';
import ReportRoutes from '../../../routing/ReportRoutes';


const { Sider, Content } = AntdLayout;
const grey = '#bab6b6';
const black = "#051235";

export const ReportsLayout = () => {

	const location = useLocation();
	const pathName = location.pathname;
	const { collapsed, setCollapsed } = useAuth();
	const { t } = useTranslation();		

	return (
		<AntdLayout>
			<Sider width={140} className='container-space'  collapsed={collapsed} collapsible={true} trigger={null}>
				<aside>
					<nav>
						<ul>							
							<Link to="/informes/lista">
								<li className={`${(pathName == '/informes/lista') ? 'item-selected' : 'item-no-selected' }${` item-space`}`}>
									<div>
										<ShareAltOutlined style={{color: (pathName == '/informes/lista') ? black : grey }} className='icon-menu-item'/>
										<span className={`${(pathName == '/informes/lista') ? 'selected' : 'no-selected' }`}>{t('subMenu.report.share')}</span>
									</div>
								</li>
							</Link> 																			
						</ul>
					</nav>
				</aside>
			</Sider>			
			<Content className='container-space'>
				<div>
					<ReportRoutes />
				</div>
			</Content>			
		</AntdLayout>
	)
};
