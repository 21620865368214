import { PlusOutlined, StarOutlined } from '@ant-design/icons';
import { Button, message, Upload } from 'antd';
import { Form } from 'antd';
import { useContext, useState } from 'react';
import { useAuth } from '../../../../../store/AuthContext/AuthContext';
import { updatePics } from '../../../../../services/updatePics';
import { UploadFile } from 'antd/lib/upload/interface';
import { IUploadProps, InfoArgs } from '../../../../../types/updates';
import { Typography } from 'antd';
import Title from 'antd/lib/typography/Title';
import { useTranslation } from 'react-i18next';
import { GlobalVariableContext } from '../../../../../store/GlobalVariableContext/GlobalVariableContext';
const { Text } = Typography;

interface Props {
  onFinish: () => void,
  selectedKeys: Array<string>,
  label?: any,
  textBtn: string,
  category: string
}

const UploadInput = ({onFinish, selectedKeys, label, textBtn, category}: Props): JSX.Element => {

  const { t } = useTranslation();
  const { token, project } = useAuth();
  const [ formLogo ] = Form.useForm();
	const [uploadFileList, setUploadFileList] = useState<Array<UploadFile>>([]);

  const {updateGlobalImage , setUpdateGlobalImage} = useContext(GlobalVariableContext)

  const resolutionLogo: string = t('view.forms.fields.photo.info.resolution.logo');
  const resolutionCover: string = t('view.forms.fields.photo.info.resolution.cover');

  const onUploadChange = (info: InfoArgs): void => {
    
    if (info?.fileList.length > 0) {
      
      if (
        info?.fileList[0]?.size! >= 5000000 ||
        info?.fileList[0]?.size! <= 10000

        ) {
        message.error(t('view.forms.fields.photo.errors.size'), 3)
        return
      }
      if (
        info?.fileList[0]?.type! !== "image/png" &&
        info?.fileList[0]?.type! !== "image/jpeg" &&
        info?.fileList[0]?.type! !== "image/jpg"
      ) {
        message.error(t('view.forms.fields.photo.errors.extension'), 3)
        return
      }
    }

    let fileList = [...info.fileList];
    fileList = fileList.slice(-1 * 1)
		fileList = fileList.map(file => {
			if (file.response) {
				file.url = file.response.url;
			}
			return file;
		});
		setUploadFileList(fileList);		
	}

	const uploadProps: IUploadProps = {
		action: `${process.env.REACT_APP_API}/projects/${project}/media`,
		accept: 'image/jpeg,image/png,image/jpg',
		headers: {
			Authorization: 'Bearer ' + token
		},		 
		onChange: onUploadChange,
		multiple: true,		 
		fileList: uploadFileList,
	};

  	const submitMedia = async (values: any) => {
      try {
        const url: string = values.photo.fileList[0].url;
        const data = {
          category,
          photoURL: url,
          locationsNames: selectedKeys
        };
        await updatePics({ 
          config: { method: 'POST', data }, 
          type: "localPostMedia"
        })
        message.success(t('view.forms.fields.photo.success'), 3)
        setUpdateGlobalImage(!updateGlobalImage);
        onFinish() 
        setUploadFileList([])
      } catch (error: any) {
        message.error(t('view.forms.fields.photo.errors.edit'), 3)
      }
    };

  return (
    <>
    <Form 
      layout='vertical' 
      form={formLogo} 
      onFinish={submitMedia}
      style={{display: 'flex', flexDirection: 'column', gap: '.5rem', justifyContent: 'center', alignItems: 'center'}}
    >
        <Title level={4}>{t('dictionary.requirements')}:</Title>
        <Text style={{textAlign: 'center'}}>
          <b>{t('dictionary.format')}:</b> {t('view.forms.fields.photo.info.format')}.<br/>
          <b>{t('dictionary.size')}:</b> {t('view.forms.fields.photo.info.size')}.<br/>
          <b>{t('dictionary.resolution')}:</b>
            {category === "LOGO" ? resolutionLogo : resolutionCover }
        </Text>
      
      
	    <Form.Item 
        label={label} 
        name='photo'
        style={{textAlign: 'center'}}
      >							
		    <Upload {...uploadProps} listType='picture-card'>
			    {
            uploadFileList.length < 1 && (
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>
                  {t('view.forms.fields.photo.label')}
                </div>
              </div>
                                          )
          }						
		    </Upload>
	    </Form.Item>    
      <Button 
        disabled={uploadFileList.length <= 0}
        shape='round' 
        type='primary' 
        onClick={formLogo.submit}
      >
		    {textBtn}
	    </Button>      
    </Form>
    </>
						
	)
};

export default UploadInput
