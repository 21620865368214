import { Card, Col, Rate, Row, Tag } from "antd";
import { useState } from 'react'
import { useAuth } from '../../../../store/AuthContext/AuthContext';
import { Column, Area } from '@ant-design/plots';
import { useFetch } from "../../../../hooks/useFetch/useFetch";
import { getProjectStorage } from "../../../../store/storageContext";
import { getColor } from "../../../../common/utils/ColorRate";
import { InfoCircleOutlined, LoadingOutlined, StarFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import './ReviewsSummary.css';
import '../../../../styles/Summaries.css';
import MathUtils from "../../../../common/utils/MathUtils";
import { SpinLZ } from "../../../components/design/Spinner/SpinLZ";
import PDFGenerator from "../../../components/model/pdf/GeneratePDF";
import { SCHEDULEDJOB } from "../../../../common/constants/services";
import { Synchronized } from "../../../components/design/Synchronized/Synchronized";
import { useHistory, useLocation } from "react-router-dom";
import { BlockReviewsKpis } from "../blocks/BlockReviewsKpis";
import { BlockReviewsGlobal } from "../blocks/BlockReviewsGlobal";
import { BlockReviewsByLocation } from "../blocks/BlockReviewsByLocation";

export const ReviewsSummary = () => {

    const { groupAccount } = useAuth();
    
    const [reviewsKPIs, setReviewsKPIs] = useState<any>(null);
    const [reviewsData, setReviewsData] = useState<any>();
    const [locationInfo, setLocationInfo] = useState<any>();
    const [isLoading, setIsLoading] = useState(true);
    
    const { t } = useTranslation();
    const location = useLocation();
        
    let locationId = new URLSearchParams(location.search).get('locationId');
    locationId = locationId != null ? "?locationId=" + locationId : "";
    
    
    useFetch(`${process.env.REACT_APP_API}/projects/` + getProjectStorage() + '/' + groupAccount + '/summary/reviews' + locationId, {}, [],    
        (data: any) => {
            setReviewsKPIs(data.reviewsKPIs);
            setReviewsData(data);
            setIsLoading(false);
            setLocationInfo(data.location);
        });

    return (
        <div>
            <Card className='main-card-content' id='element-to-capture'>

                <Synchronized service={[SCHEDULEDJOB.GBP_REVIEWS]}/>	
		                
                <h1 className='title-card'>
                    Estadísticas de Reseñas
                </h1>                                

                {isLoading ? <SpinLZ /> : <div className="card-container">
                    {reviewsKPIs && <BlockReviewsKpis reviewsKPIs={reviewsKPIs}/>}
                    <h2>Último año:</h2>
                    {reviewsData && <BlockReviewsGlobal data={reviewsData}/>}                    
                    <br/><h2>Por Local:</h2>
                    {reviewsData && <BlockReviewsByLocation data={reviewsData.locationsKpis} showButtons={true}/>}                                        
                </div>
                }
            </Card>
        </div>
    )
}