import { BarChartOutlined, BarsOutlined, CommentOutlined, QuestionCircleOutlined, DoubleRightOutlined, QrcodeOutlined, RollbackOutlined, StarOutlined, StarTwoTone, HomeOutlined } from '@ant-design/icons';
import { Layout as AntdLayout, Menu } from 'antd';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import GoogleClientsRoutes from '../../../routing/GoogleClientsRoutes';
import { useAuth } from '../../../store/AuthContext/AuthContext';
import { useIsAuthorized } from '../../pages/common/roles/Roles';


const { Sider, Content } = AntdLayout;
const grey = '#bab6b6';
const black = "#051235";

const GoogleClientsLayout = () => {

	const location = useLocation();
	const pathName = location.pathname;
	const { collapsed, setCollapsed } = useAuth();
	const isAuthorized = useIsAuthorized();
	const { t } = useTranslation();		

	return (
		<AntdLayout>
			<Sider width={140} className='container-space'  collapsed={collapsed} collapsible={true} trigger={null}>
				<aside>
					<nav>
						<ul>
							<Link to="/clientes/locationsreviews">
								<li className={`${(pathName == '/clientes/locationsreviews') ? 'item-selected' : 'item-no-selected' }${` item-space`}`}>
									<div>
										<HomeOutlined style={{color: (pathName == '/clientes/locationsreviews') ? black : grey }} className='icon-menu-item'/>
										<span className={`${(pathName == '/clientes/locationsreviews') ? 'selected' : 'no-selected' }`}>{t('subMenu.reviews.list')}</span>
									</div>
								</li>
							</Link>
							<Link to="/clientes/reviews">
								<li className={`${(pathName == '/clientes/reviews') ? 'item-selected' : 'item-no-selected' }${` item-space`}`}>
									<div>
										<StarOutlined style={{color: (pathName == '/clientes/reviews') ? black : grey }} className='icon-menu-item'/>
										<span className={`${(pathName == '/clientes/reviews') ? 'selected' : 'no-selected' }`}>{t('subMenu.reviews.reviews')}</span>
									</div>
								</li>
							</Link>
							<Link to="/clientes/questions">
								<li className={`${(pathName == '/clientes/questions') ? 'item-selected' : 'item-no-selected' }${` item-space`}`}>
									<div>
										<QuestionCircleOutlined style={{color: (pathName == '/clientes/questions') ? black : grey }} className='icon-menu-item'/>
										<span className={`${(pathName == '/clientes/questions') ? 'selected' : 'no-selected' }`}>{t('subMenu.reviews.questions')}</span>
									</div>
								</li>
							</Link>
							{ isAuthorized(['ROLE_ADMIN', 'ROLE_AGENT', 'ROLE_CLIENT']) && <Link to="/clientes/qr">
								<li className={`${(pathName == '/clientes/qr') ? 'item-selected' : 'item-no-selected' }${` item-space`}`}>
									<div>
										<QrcodeOutlined style={{color: (pathName == '/clientes/qr') ? black : grey }} className='icon-menu-item'/>
										<span className={`${(pathName == '/clientes/qr') ? 'selected' : 'no-selected' }`}>{t('subMenu.reviews.qr')}</span>
									</div>
								</li>
							</Link>
							}
							<Link to="/clientes/global">
								<li className={`${(pathName == '/clientes/global') ? 'item-selected' : 'item-no-selected' }${` item-space`}`}>
									<div>
										<BarChartOutlined style={{color: (pathName == '/clientes/global') ? black : grey }} className='icon-menu-item'/>
										<span className={`${(pathName == '/clientes/global') ? 'selected' : 'no-selected' }`}>{t('subMenu.reviews.stats')}</span>
									</div>
								</li>
							</Link> 
						</ul>
					</nav>
				</aside>
			</Sider>			
			<Content className='container-space'>
				<div>
					<GoogleClientsRoutes />
				</div>
			</Content>			
		</AntdLayout>
	)
};

export default GoogleClientsLayout;