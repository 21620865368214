import { useState, createContext } from 'react';

export const DrawerContext = createContext<{

    locationDrawer: any, 
    locationStatisticsDrawer: any, 
    reviewsDrawer:any,
    questionsDrawer:any,
    postsDrawer:any,
    scheduledPostsDrawer:any,
    logsDrawer: boolean,
    userDrawer:any,
    setLocationDrawer:React.Dispatch<React.SetStateAction<any>>
    setLocationStatisticsDrawer:React.Dispatch<React.SetStateAction<any>>
    setReviewsDrawer:React.Dispatch<React.SetStateAction<any>>
    setQuestionsDrawer:React.Dispatch<React.SetStateAction<any>>
    setPostsDrawer:React.Dispatch<React.SetStateAction<any>>
    setScheduledPostsDrawer:React.Dispatch<React.SetStateAction<any>>
    setLogsDrawer:React.Dispatch<React.SetStateAction<any>>
    setUserDrawer:React.Dispatch<React.SetStateAction<any>>    
}>({
    locationDrawer: null, 
    locationStatisticsDrawer: null, 
    reviewsDrawer: null,
    questionsDrawer: null,
    postsDrawer: null,
    scheduledPostsDrawer: null,
    logsDrawer:false,
    userDrawer:null,
    setLocationDrawer:()=>{},
    setLocationStatisticsDrawer:()=>{},
    setReviewsDrawer:()=>{},
    setQuestionsDrawer:()=>{},
    setPostsDrawer:()=>{},
    setScheduledPostsDrawer:()=>{},
    setLogsDrawer: () => {},
    setUserDrawer:() => {}
})
const DrawerProvider: React.FC = ({ children }) => {

    const [locationDrawer, setLocationDrawer] = useState(null);
    const [locationStatisticsDrawer, setLocationStatisticsDrawer] = useState(null);
    const [reviewsDrawer, setReviewsDrawer] = useState(null);
    const [questionsDrawer, setQuestionsDrawer] = useState(null);
    const [postsDrawer, setPostsDrawer] = useState(null);
    const [scheduledPostsDrawer, setScheduledPostsDrawer] = useState(null);
    const [logsDrawer, setLogsDrawer] = useState(false);
    const [userDrawer, setUserDrawer] = useState(null);

    return (
        <DrawerContext.Provider value={{ locationStatisticsDrawer, setLocationStatisticsDrawer, locationDrawer, setLocationDrawer, reviewsDrawer, setReviewsDrawer, questionsDrawer, setQuestionsDrawer, postsDrawer, setPostsDrawer, logsDrawer, setLogsDrawer, userDrawer, setUserDrawer, scheduledPostsDrawer, setScheduledPostsDrawer}}>
            {children}
        </DrawerContext.Provider>    
    );
};

export default DrawerProvider;