import { CheckCircleTwoTone, PhoneOutlined, ShopFilled, WarningTwoTone } from "@ant-design/icons"
import { Badge, Button, Card, Tag, Tooltip } from "antd"
import { STATUS } from "./OpenForBusiness"
import { VERIFY_STATUS, VERIFY_STATUS_COLOR } from "./VerifyStatus"

export const Location = (props: {record: any, onChange?: any, drawer?: boolean}) => {

	return (
		<div style={{background:'white', borderRadius:'10px', padding :'10px', display:'flex', justifyContent:'space-between'}}>
			<div style={{display:'flex'}}>
				<Tooltip title={VERIFY_STATUS[props.record.status]}>					
					<Badge status={VERIFY_STATUS_COLOR[props.record.status]}/>
				</Tooltip>
				<div>
					<span style={{fontWeight: 600, fontSize: "15px", paddingRight:'10px'}}> {props.record.title}</span><br/>
					<AddressGroup record={props.record}/>
					{ props.record.openForBusiness?.status?.includes('CLOSED') && <div style = {{color:'red'}}>{STATUS[props.record.openForBusiness?.status]}</div>}					
				</div>
			</div>
			{props.drawer && <Button onClick={() => {props.onChange()}} size='small' style={{ borderRadius: '20px', background:'white',  border:`solid 1.3px RoyalBlue`, color: "RoyalBlue" }}>    
				<small><ShopFilled/> Ver más</small>
			</Button>}
		</div>
	)
}

export const Categories = (props: {categories: any}) => {

	return (		
		<div>
            <ul>
                <li>
                    <b style={{ color: "#666" }}>
                        {props.categories.primaryCategory.displayName}
                    </b>
                </li>
                {
                    props.categories.additionalCategories && props.categories.additionalCategories.map((additionalCategory: any, i: number) => (
                        <li style={{ color: "#666" }} key={i}>
                            {additionalCategory.displayName}
                        </li>
                    ))
                }
            </ul>
        </div>		
	)
}

export const NameGroup = (props: {record: any}) => {

	return (
		<>			
			<span style={{paddingRight:'10px'}}>{props.record.title}</span><br/>
			<small style={{color:'grey'}}>{props.record.categories?.primaryCategory?.displayName}</small>
		</>
	)
}

export const StatusGroup = (props: {record: any}) => {

	return (
		<p>
			{props.record.status == 'HAS_VOICE_OF_MERCHANT' ?<CheckCircleTwoTone twoToneColor="#52c41a" /> : <WarningTwoTone twoToneColor="#eb2f96" />}<br/>
		</p>
	)
}

export const AddressGroup = (props: {record: any}) => {
	
	return (
	  (props.record.locality !== null && props.record.administrativeArea !== null) ? (
		<>
		  <span style={{ fontWeight: 600, fontSize: "13px", color:'SteelBlue' }}>{props.record.locality},</span>
		  <i style={{ fontWeight: 600, fontSize: "13px", color:'SteelBlue' }}> {props.record.administrativeArea}<br /></i>
		  {props.record.addressLine !== null && (
			<small style={{ color: '#808080', fontSize: "12px" }}>
			  {props.record.addressLine}
			</small>
		  )}
		</>
	  ) : (
		<small style={{ color: '#808080', fontSize: "12px" }}>Sin ubicación</small>
	  )
	);
  };
  
export const StoreCodeGroup = (props: {record: any}) => {

  	return (
		<>
			{
				props.record.storeCode ?
					props.record.storeCode : "-"
			}
			{
				props.record.todayPosts && 
					<CheckCircleTwoTone/>
			} 
		</>
	)
}

export const ContactGroup = (props: {record: any}) => {
		
		return (
			<>
				<a>{props.record.websiteUri}</a><br/>
				<span style={{marginRight:"1em"}}><PhoneOutlined/> {props.record.primaryPhone}</span>
				{ props.record.additionalPhones &&
				props.record.additionalPhones.map((additional:string, i:number)=>(
					<span style={{marginRight:"1em"}}><PhoneOutlined/> {additional}</span>
				))}
			</>
	  )
}

// *********************** WORKING ON ******************
