import { useContext, useState } from 'react';
import { Card, Radio, Timeline, Tag, Row, Col, Pagination, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useFetch } from '../../../../hooks/useFetch/useFetch';
import { useAuth } from '../../../../store/AuthContext/AuthContext';
import { CalendarOutlined, LoadingOutlined, NotificationOutlined, TagsOutlined } from '@ant-design/icons';
import Search from 'antd/lib/input/Search';
import { getProjectStorage } from '../../../../store/storageContext';
import Post from '../../../components/model/posts/Post';
import '../../pages.scss';
import { Synchronized } from '../../../components/design/Synchronized/Synchronized';
import { SCHEDULEDJOB } from '../../../../common/constants/services';
import { PostsHistoricalDrawer } from './PostsHistoricalDrawer';
import moment from 'moment';
import { DrawerContext } from '../../../../store/DrawerContext/DrawerContext';

const GooglePosts = () => {

	const [postLocationsVisible, setPostLocationsVisible] = useState(false);
	const [refreshPosts, setRefreshPosts] = useState(false);
	const { groupAccount } = useAuth();
	const [postsGroupsData, setPostsGroupsData] = useState([]);
	const [postGroupSelected, setPostGroupSelected] = useState();

	const [postsKPIs, setPostsKpis] = useState();
	const [pagination, setPagination] = useState({ current: 1, total: 1 });
	const [nextPag, setNextPag] = useState(1);
	const [lastGroupAccount, setLastGroupAccount] = useState(groupAccount);
	const { setLogsDrawer } = useContext(DrawerContext);

	const [filterAdministrativeArea, setFilterAdministrativeArea] = useState('');
	const [filterPostalCode, setFilterPostalCode] = useState('');
	const [filterAddress, setFilterAddress] = useState('');
	const [filterLocality, setFilterLocality] = useState('');
	const [filterType, setFilterType] = useState('all');
	const [filterByDate, setFilterByDate] = useState("90_DAYS");
	const { t } = useTranslation();

	useFetch(`${process.env.REACT_APP_API}/projects/` + getProjectStorage() + '/' + groupAccount + '/posts?' +
		`type=${filterType}&administrativeArea=${filterAdministrativeArea}&locality=${filterLocality}&postalCode=${filterPostalCode}&address=${filterAddress}&dateRange=${filterByDate}&` +
		(groupAccount == lastGroupAccount ? `page=${nextPag}` : 'page=1'), {}, [refreshPosts],
		(data) => {
			setPostsGroupsData(data.postsGroups);
			setPostsKpis(data.kpis);
			setPagination(data.pagination);
			setNextPag(data.pagination.current);

			setLastGroupAccount(groupAccount);
		});


	return (
		<Card className='main-card-content'>
			
			
			<div style= {{width:'100%', display:'flex', justifyContent:'space-between'}}>
				<h1 className='title-card'>{t('dictionary.postsHistory')}</h1>			
				<Button type='link' onClick={() => setLogsDrawer(true)}>
					Ver Registro
				</Button>		
			</div>
			<Synchronized service={[SCHEDULEDJOB.GBP_POSTS]}/>	
			
			<div style={{ width: '100%', clear: 'right' }}>

			</div>
			{/*postsKPIs && <PostsKPIs postsKPIs={postsKPIs} />*/}
			{/*<Divider orientation="left"><small>Filtros</small></Divider>*/}
			<br />
			{/*<small style={{ color: 'dodgerblue' }}>Filtros:</small>*/}
			<Card style={{ marginTop: '10px' }}>
				<Row gutter={16} style={{ marginTop: '10px' }}>
					<Col span={24}>
						<Radio.Group value={filterByDate} onChange={(obj: any) => { setFilterByDate(obj.target.value) }} style={{ marginRight: "20px" }} >
							<Radio.Button value="90_DAYS">90 {t('dictionary.days')}</Radio.Button>
							<Radio.Button value="6_MONTHS">6 {t('dictionary.months')}</Radio.Button>
							<Radio.Button value="1_YEAR">1 {t('dictionary.year')}</Radio.Button>
						</Radio.Group>
						<Radio.Group value={filterType} onChange={(obj: any) => { setFilterType(obj.target.value) }}>
							<Radio.Button value="all">{t('dictionary.all.plural.female')}</Radio.Button>
							<Radio.Button value="STANDARD"><NotificationOutlined /> {t('dictionary.novelties')}</Radio.Button>
							<Radio.Button value="OFFER"><TagsOutlined /> {t('dictionary.offers')}</Radio.Button>
							<Radio.Button value="EVENT"><CalendarOutlined /> {t('dictionary.events')}</Radio.Button>
						</Radio.Group>
						{/*<Radio.Group style={{marginLeft:'15px'}} value={filterMassive} onChange={(obj:any) => {setFilterMassive(obj.target.value)}}>
						<Radio.Button value="all">Todas</Radio.Button>
						<Radio.Button value="massive">Masivas</Radio.Button>
						<Radio.Button value="unique">Un negocio</Radio.Button>
					</Radio.Group>*/}
					</Col>
				</Row>

				<Row gutter={16} style={{ marginTop: '10px' }}>

					<Col span={6}>
						<Search style={{ alignContent: '100%' }} size='small' placeholder={t('dictionary.province') as string} allowClear onSearch={(value: string) => { setFilterAdministrativeArea(value) }} />
					</Col>
					<Col span={6}>
						<Search style={{ alignContent: '100%' }} size='small' placeholder={t('dictionary.city') as string} allowClear onSearch={(value: string) => { setFilterLocality(value) }} />
					</Col>
					<Col span={6}>
						<Search style={{ alignContent: '100%' }} size='small' placeholder={t('dictionary.postalCode') as string} allowClear onSearch={(value: string) => { setFilterPostalCode(value) }} />
					</Col>
					<Col span={6}>
						<Search style={{ alignContent: '100%' }} size='small' placeholder={t('dictionary.address') as string} allowClear onSearch={(value: string) => { setFilterAddress(value) }} />
					</Col>
				</Row>
			</Card>
			<Row gutter={16} style={{ marginTop: '10px' }}>
				{postsGroupsData.length > 0 &&<>
				<Col span={12}>
					<><b>{t('dictionary.page')} {pagination.current}:</b> {postsGroupsData.length} {t('dictionary.posts').toLowerCase()}</>
				</Col>
				<Col span={12}>
					<Pagination size='small' style={{ float: 'right' }}
						simple defaultPageSize={10} current={pagination.current} total={pagination.total}
						onChange={(page) => { setNextPag(page); } } />
				</Col></>
				}
			</Row>
			<div style={{ margin: '20px 0px 30px 0px' }}>

				<Timeline mode={'right'} >
					{
						postsGroupsData.map((postGroupData: any) => {
							return (
								<Timeline.Item
									label={
										<Card bodyStyle={{ background: 'white', borderRadius: '20px', width: '60%' }}>
											<Tag style={{ borderRadius: '20px' }}>{postGroupData.postType}</Tag>
											<div style={{ marginTop: '15px', fontSize: '20px' }}><b>{moment(postGroupData.createdDate).format('ll')}</b></div>
											<div style={{ color: 'grey', marginTop: '30px' }}>{t('dictionary.publishedOnStores', { length: postGroupData.size })}
												<div style={{ fontWeight: 'bold' }}><a onClick={() => { setPostLocationsVisible(true); setPostGroupSelected(postGroupData) }}>{t('dictionary.viewList').toUpperCase()} {'>>>'}</a></div>
											</div>
										</Card>
									}>
									<div style={{ width: '85%', marginLeft: '15%', minHeight:'200px' }}>
										<Post postGroup={postGroupData} />
									</div>
								</Timeline.Item>
							)
						})
					}
				</Timeline>
			</div>
			<Row gutter={16} style={{ marginBottom: '10px' }}>
				{postsGroupsData.length > 0 && <>
				<Col span={12}>
					<><b>{t('dictionary.page')} {pagination.current}:</b> {postsGroupsData.length} {t('dictionary.posts').toLowerCase()}</>
				</Col>
				<Col span={12}>
					<Pagination size='small' style={{ float: 'right' }}
						simple defaultPageSize={10} current={pagination.current} total={pagination.total}
						onChange={(page) => { setNextPag(page); } } />
				</Col></>}
			</Row>
			{
				postLocationsVisible && (
					<PostsHistoricalDrawer setRefreshPosts={setRefreshPosts} refreshPosts={refreshPosts} postGroup={postGroupSelected} postLocationsVisible={postLocationsVisible} setPostLocationsVisible={setPostLocationsVisible} />
				)}
		</Card>
	)
}

export default GooglePosts;