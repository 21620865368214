import { InfoCircleTwoTone, MailTwoTone } from '@ant-design/icons';
import { Alert, Card, Form, Input, message, Modal } from 'antd';
import { useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Trans, useTranslation } from 'react-i18next';
import { useAuth } from '../../../store/AuthContext/AuthContext';
import { authFetch } from '../../../common/request/authFetch';
import { ButtonLZ } from '../../components/design/Buttons/ButtonLZ';
import { IconsGoogle } from '../../components/design/icons/IconsGoogle';
import LanguageSelector from '../../components/model/language/Selector';
import background from '../../../assets/greyMap.png';
import './Login.css'

interface IServerError {
	statusCode?: string,
	message?: string,
	success?: boolean
}

const Login = (): JSX.Element => {
	const [formAuthError, setFormAuthError] = useState<boolean>(false);
	const [serverError, setServerError] = useState<IServerError>({});
	const [user, setUser] = useState<string>('');
	const location = useLocation();
	const history = useHistory();
	const { logIn } = useAuth();
	const { t } = useTranslation();

	const onSubmit = (data: any): Promise<void> => {
		const state: any = location.state
		return logIn(data).then((res: any) => {
			if (res.success) {
				setServerError({})
				setFormAuthError(false);
				//console.log(state?.from?.pathname);

				// state?.from?.pathname ?? <-- esto da error la primera vez que se inicia sesion
				return history.push( state?.from?.pathname ?? '/home');
			}
			if (res.statusCode !== 401) {
				return setServerError({
					statusCode: res.statusCode,
					message: res.message
				})
			}
			return setFormAuthError(true)
		})
	};

	const sendRecoverEmail = async (): Promise<any> => {
		return await authFetch(`${process.env.REACT_APP_API}/users/${user}/recover`, { method: 'POST' })
			.then((isOk) => {
				if(isOk) {
					return message.success(t('view.forms.modal.mail.success', { user }));
				} else {
					return message.error(t('view.forms.modal.mail.error', { user }));
				}
			}
			);
	}

	return (
		<>
		<div >
			<div
				style={{ position: 'fixed', bottom: 0, right: 0, backgroundImage: `url(${background})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", width: '100%', height: '100vh', opacity: '0.5' }}

			/>

			<div className='container'>
				<span className='span1'/>
				<span className='span2' />
				<span className='span3'/>
				<span className='span4'/>
				<span className='span5'/>
				<span className='span6'/>
				<span className='span7'/>
			</div>
			

		</div>
			<div>
				<div className='login-general-two'>
					<Card bordered={true} className="login-card" style={{borderRadius:'50%'}}>
						<div className='login-form'>
							<div className='logo-header'>
							</div>
							<div>
								<h2 className='title'>{t('dictionary.login')}</h2>
							</div>
							<Form layout='vertical' name='basic' onFinish={onSubmit} autoComplete='off' style={{ width: '82%' }}>
								<Form.Item
									label={t('dictionary.user')}
									name='username'
									validateTrigger='onBlur'
									rules={[{
										required: true,
										type: 'email',
										message: t('view.forms.fields.user.required') as string
									}]}
								>
									<Input
										autoComplete='off'
										placeholder={t('view.forms.fields.user.placeholder') as string}
										prefix={<IconsGoogle style='material-symbols-outlined' name='person' />}
										onChange={(e) => setUser(e.target.value)} />
								</Form.Item>
								<Form.Item
									label={t('dictionary.password')}
									name='password'
									rules={[{
										required: true,
										message: t('view.forms.fields.password.required') as string
									}]}
								>
									<Input.Password
										autoComplete='off'
										placeholder={t('view.forms.fields.password.placeholder') as string}
										prefix={<IconsGoogle style='material-symbols-outlined' name='key' />} />
								</Form.Item>
								{formAuthError && <Alert message={t('view.forms.modal.login.error')} type="warning" showIcon />}
								<ButtonLZ
									htmlType="submit"
									color='yellow'
									shape='round'
									width='35%'
								style={{marginTop:'13px'}}
								>
									{t('dictionary.login')}
								</ButtonLZ>
							</Form>

							{/*
        <Alert
                                    style={{ marginTop: '12px' }}
                                    type='error'
                                    message={`Status code: ${serverError.statusCode}`}
                                    />
    */}
							{user ?
								<button
									className='missing-password'
									onClick={() => {
										Modal.confirm({
											title: <p>
												<Trans t={t} i18nKey="view.forms.modal.forgotPassword.title" values={{ user }} >
												¿Enviar correo de recuperación a <b style={{ color: 'dodgerblue' }}>{{user}}</b>?
												</Trans>
											</p>,
											content: <p>{t('view.forms.modal.forgotPassword.content')}</p>,
											okText: t('dictionary.send'),
											cancelText: t('dictionary.cancel'),
											icon: <MailTwoTone />,
											onOk: () => sendRecoverEmail()
										});
								} }
								>
									{t('dictionary.forgotPassword')}
								</button>
								:
								<button
									className='missing-password'
									onClick={() => {
										Modal.info({
											title: t('view.forms.modal.forgotPassword.error'),
											icon: <InfoCircleTwoTone />
										});
								} }
								>
									{t('dictionary.forgotPassword')}
								</button>}
						</div>
					</Card>
					<div style={{ position: 'fixed', top: '15px', right: '15px' }}>
						<LanguageSelector />
					</div>
				</div>
			</div>

		</>
	);
}

export default Login;